<template>
	<div class="order_wrap"></div>
</template>
<script>
import { signedList } from '@/apis/esign.api'
export default {
	async mounted() {
		const { q } = this.$route.query
		if (!q) {
			this.$router.push({
				name: 'esign-expire',
				params: {
					msg:
						'일시적인 오류가 발생했습니다.<br />잠시후 다시 시도해주세요.<br /><br />동일한 문제가 발생하시면<br /><br />고객센터로 연락주시기 바랍니다.<br />고객센터 연락처 : 1588-5200'
				}
			})
			return
		}
		const res = await signedList(q)
		const { MESSAGE: message, TYPE: type } = res.resultObject.ES_RETURN
		if (type === 'T') {
			this.$router.push({
				name: 'esign-download-list',
				params: { result: res.resultObject }
			})
		} else {
			this.$router.push({
				name: 'esign-expire',
				params: { msg: message }
			})
		}
	},
	components: {},
	data() {
		return {}
	},
	methods: {}
}
</script>

<style scoped lang="scss"></style>
