import api from '@/apis/config'
const { get } = api

/**
 * 서명 완료 목록 조회
 * 서명완료 리스트 및 계약서를 조회합니다
 * @param {string} zsndNo: 발송대상번호
 */
export const signedList = async zsndNo => {
	const response = await get(`/v1/21/03/${zsndNo}`)
	return response
}
